export const THE_GRAPH_URL = "https://api.thegraph.com/subgraphs/name/xiaxuliang/yapgraph";
export const EPOCH_INTERVAL = 9600;

// NOTE could get this from an outside source since it changes slightly over time
export const BLOCK_RATE_SECONDS = 3;

export const TOKEN_DECIMALS = 9;

interface IPoolGraphURLS {
  [index: string]: string;
}

export const POOL_GRAPH_URLS: IPoolGraphURLS = {
  4: "https://api.thegraph.com/subgraphs/name/pooltogether/rinkeby-v3_4_3",
  1: "https://api.thegraph.com/subgraphs/name/pooltogether/pooltogether-v3_4_3",
};

interface IAddresses {
  [key: number]: { [key: string]: string };
}

export const addresses: IAddresses = {
  4: {
    DAI_ADDRESS: "0xB2180448f8945C8Cc8AE9809E67D6bd27d8B2f2C", // duplicate
    OHM_ADDRESS: "0xC0b491daBf3709Ee5Eb79E603D73289Ca6060932",
    STAKING_ADDRESS: "0xC5d3318C0d74a72cD7C55bdf844e24516796BaB2",
    STAKING_HELPER_ADDRESS: "0xf73f23Bb0edCf4719b12ccEa8638355BF33604A1",
    OLD_STAKING_ADDRESS: "0xb640AA9082ad720c60102489b806E665d67DCE32",
    SOHM_ADDRESS: "0x1Fecda1dE7b6951B248C0B62CaeBD5BAbedc2084",
    WSOHM_ADDRESS: "0xe73384f11Bb748Aa0Bc20f7b02958DF573e6E2ad",
    OLD_SOHM_ADDRESS: "0x8Fc4167B0bdA22cb9890af2dB6cB1B818D6068AE",
    MIGRATE_ADDRESS: "0x3BA7C6346b93DA485e97ba55aec28E8eDd3e33E2",
    DISTRIBUTOR_ADDRESS: "0x0626D5aD2a230E05Fb94DF035Abbd97F2f839C3a",
    BONDINGCALC_ADDRESS: "0xaDBE4FA3c2fcf36412D618AfCfC519C869400CEB",
    CIRCULATING_SUPPLY_ADDRESS: "0x5b0AA7903FD2EaA16F1462879B71c3cE2cFfE868",
    TREASURY_ADDRESS: "0x0d722D813601E48b7DAcb2DF9bae282cFd98c6E7",
    REDEEM_HELPER_ADDRESS: "0xBd35d8b2FDc2b720842DB372f5E419d39B24781f",
    PT_TOKEN_ADDRESS: "0x0a2d026bacc573a8b5a2b049f956bdf8e5256cfd", // 33T token address, taken from `ticket` function on PRIZE_STRATEGY_ADDRESS
    PT_PRIZE_POOL_ADDRESS: "0xf9081132864ed5e4980CFae83bDB122d86619281", // NEW
    PT_PRIZE_STRATEGY_ADDRESS: "0x2Df17EA8D6B68Ec444c9a698315AfB36425dac8b", // NEW
    XPH_ADDRESS: "",
    PreSale_ADDRESS: "",
  },
  /*
pair:0xf9d4bC2F9aff72De97C263434092eFC51EEa5388
YAP:0xAACbceA4923DF9E6D6a14F1Cd7752461C657dE2e
Treasury:0x24C334C527f4C2323Dd2c9A82898E27F612B94c5
Calc:0xa4a5526FB45A2EcDA2Ff8b829b096dfBBd29E806
Distributor:0x765c4d2Dfa4CAd8d345f154466e03f8a5F0D4faa
sYAP:0xa76CAA9f38Dd8233687a34320F07106D660eE156
Staking:0x858f4725b0D5Ac8BE3F31FB58123b4b334AE3F4E
Staking Wawrmup:0x7972bD6C65B71B58C0D0e557D0E7e486dD756afF
Staking Helper:0xB989B6aBc7e9C85BC63BDe061E2bAF56E1BA7Bb7
Staking Cliam:0x9a0420e094bf548571B393D3E304b9Ae814DBde3
invite:0xc018Bc541E80354c6eED8e8cA7537eAe735A7819
YP:0xc73Bf4FdCA7cd6F82Cf29CE65112F0A328EE229C
Reward:0x2A818B45A851D7ab958a4DDe400C367730c34f70
LPBond:0x287adA783CC406bb9080Fb784409b5d38b344a94
BondHelper:0x0e7a9b990961801E52CD8acb058b6B7eeD194BC4
  */
  97: {
    StakingCliam: "0x086a76fE0288D396e88A364F03246fa6Cf11259c",
    YP: "0x3CA96878a9F9E648c691e1C17cE99D19d8F1220A",
    Reward: "0xdC3e7ACB7AB426da4A0F112d46bD63Ee4d4feedC",
    invite: "0xa3B9501b50Bdf438f9A6E73f1923E03A7f486342",
    DAOaddr: "0x1f6Aef0dfD9d5Fb4a7c07F6b63fa7fCCF4b0F930",
    USDT: "0x22A5A72801997fB54C11c9478481bbB6cCED43ae",
    // duplicate
    OHM_ADDRESS: "0x40d7ff456A70A0B4D187F4F6f58841F28Ed0f5fe",
    STAKING_ADDRESS: "0xCeB9D0aa77D35Bca1116ed8606dFf5Ec907f37Ca",
    STAKING_HELPER_ADDRESS: "0x78068012c09ae6172B808BE22B7b92cf244E3dE2",
    SOHM_ADDRESS: "0x46723C60aC92A48aeca0534F1Fe8d8eCd18BAA4C",
    DISTRIBUTOR_ADDRESS: "0x852BF14127cABc1c105f35E15DB3DED03a4d0439",
    BONDINGCALC_ADDRESS: "0x0E59aA96845473Aa3B8A93242fa1e3c528c65Dff",
    TREASURY_ADDRESS: "0xb8b970B6d633Dd046A382496D81E398AED707958",
  },
  56: {
    StakingCliamData: "0x2efb26f1204b75112Ea8FB9e77c759eCBBeEB373",
    StakingCliamV2: "0x73a1633a0D2448BE96FeA64AD95021d2EA4D4C6C",
    StakingCliam: "0x8dbe1647f48F9e830C795c7DC1eDA671F0bDD663",
    YP: "0xD6c5cF948DD3c8139e18dBa87d3978c411cF204c",
    Reward: "0xF16122Acc6aa0C9e8faf0715674912b623679d9f",
    invite: "0x679D6203839DFa158B4494549E5E8B0D47E18a9A",
    DAOaddr: "0x3B5568f8Cd364E13EaF4B38402E95a3C6a8A5110",
    USDT: "0x55d398326f99059ff775485246999027b3197955",
    OHM_ADDRESS: "0x22bE38375124aA1AdA0c25B515ad5744A59b0885",
    STAKING_ADDRESS: "0x70395471Ced17325737DD9D79669D065210B9c4B",
    STAKING_HELPER_ADDRESS: "0x88ED7EEA9156aB4240e5BDc61D267f1436E42E46",
    SOHM_ADDRESS: "0xf243d51C6Bb0dEdF2BdD038DE2f17c65D23E9F3c",
    DISTRIBUTOR_ADDRESS: "0x5A735d2f4d250247703071C8e77C65E0F8C67e1d",
    BONDINGCALC_ADDRESS: "0x3CA19EcF6abAAF013094FDE48a3c45cAc59E4dA8",
    TREASURY_ADDRESS: "0x94EE099e8024a0eDecEE469F50E94EE343779a3d",
    // DAI_ADDRESS: "0xe9e7cea3dedca5984780bafc599bd69add087d56", // duplicate
    // OHM_ADDRESS: "0x3e29633846E8b857B7f6d7f293F895186804264b",
    // STAKING_ADDRESS: "0xE90afe3349E42f416406c592f4B7192265085695", // The new staking contract
    // STAKING_HELPER_ADDRESS: "0x844e588746A04034242198c78C5147D026B31356", // Helper contract used for Staking only
    // // OLD_STAKING_ADDRESS: "0x0822F3C03dcc24d200AFF33493Dc08d0e1f274A2",
    // SOHM_ADDRESS: "0xD748150822d7C7e9095D014fF2e9CB27b6a102E7",
    // WSOHM_ADDRESS: "0xca76543cf381ebbb277be79574059e32108e3e65",
    // // OLD_SOHM_ADDRESS: "0x31932E6e45012476ba3A3A4953cbA62AeE77Fbbe",
    // // PRESALE_ADDRESS: "0xcBb60264fe0AC96B0EFa0145A9709A825afa17D8",
    // AOHM_ADDRESS: "0x24ecfd535675f36ba1ab9c5d39b50dc097b0792e",
    // MIGRATE_ADDRESS: "0xC7f56EC779cB9e60afA116d73F3708761197dB3d",
    // DISTRIBUTOR_ADDRESS: "0x4b0b69181Fdfd61A6872FBb8eb91A8F38027B90D",
    // BONDINGCALC_ADDRESS: "0x0b335af2ADDfcC3e7D88d34451987B80DA58Ad03",
    // CIRCULATING_SUPPLY_ADDRESS: "0x0efff9199aa1ac3c3e34e957567c1be8bf295034",
    // TREASURY_ADDRESS: "0x4b69C32450cE85d76aC94215fb81C21B434696eA",
    // CRUCIBLE_OHM_LUSD: "0x2230ad29920D61A535759678191094b74271f373",
    // LQTY: "0x6dea81c8171d0ba574754ef6f8b412f2ed88c54d",
    // MIST: "0x88acdd2a6425c3faae4bc9650fd7e27e0bebb7ab",
    // REDEEM_HELPER_ADDRESS: "0x78C421664882A3da7011B125596c82c0477e9A45",
    // FUSE_6_SOHM: "0x59bd6774c22486d9f4fab2d448dce4f892a9ae25", // Tetranode's Locker
    // FUSE_18_SOHM: "0x6eDa4b59BaC787933A4A21b65672539ceF6ec97b", // Olympus Pool Party
    // FUSE_36_SOHM: "0x252d447c54F33e033AD04048baEAdE7628cB1274", // Fraximalist Money Market
    // PT_TOKEN_ADDRESS: "0x0E930b8610229D74Da0A174626138Deb732cE6e9", // 33T token address, taken from `ticket` function on PRIZE_STRATEGY_ADDRESS
    // PT_PRIZE_POOL_ADDRESS: "0xEaB695A8F5a44f583003A8bC97d677880D528248", // NEW
    // PT_PRIZE_STRATEGY_ADDRESS: "0xf3d253257167c935f8C62A02AEaeBB24c9c5012a", // NEW
    // bondHelperAddress: "0xdbee8460BF408EBa85F91317FA75A5bC6ECcdB00",
    // Ido_EXCHANGE_ADDRESS: "0x27ba2E538766D43B06D0b1E12ec3e3b2F53789f1",
    // AXPH_ADDRESS: "0xe2bef8ccbbf99d4297bd66493471c3aa85717d34",
    // DAO_ADDRESS: "0xe642AFe24f9229070c8A7ea82aD62DD9F8D5e093",
    // ROUTER_ADDRESS: "0xA9DB45c29Ba92B2bE17ED49853b2178E6121b369",
    // // ROUTER_ADDRESS: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    // WBNB_ADDRESS: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    // USDT_ADDRESS: "0x55d398326f99059fF775485246999027B3197955",
    // USDC_ADDRESS: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
    // BUSD_ADDRESS: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    // XPH_ADDRESS: "0x3e29633846E8b857B7f6d7f293F895186804264b",
    // SXPH_ADDRESS: "0xD748150822d7C7e9095D014fF2e9CB27b6a102E7",
    // XPHV2_ADDRESS: "0xE949a82d5338429d1825a630904a1B0E3F429B15",
    // XPH_MIRGATION_ADDRESS: "0x8D196053e3D23bb0AFc8d24D18cb70c51cb84402",
    // BNB_ADDRESS: "0x0000000000000000000000000000000000000000",
  },
};
